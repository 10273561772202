import { getPageProps } from '@internal/utils/page/getPageProps'
import type { GetStaticProps, NextPage } from 'next'
import { PHASE_PRODUCTION_BUILD } from 'next/constants'
import dynamic from 'next/dynamic'
import { SystemPageKey } from 'page/SystemPageKey'
import { RequestCombiner } from 'services/RequestCombiner'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { TPageProps } from '../page/TPageProps'

const BasePage = dynamic<TPageProps>(() =>
  import('page/BasePage').then((page) => page.BasePage)
)

/**
 * the 404 page is a bit special. it gets generated at build time, but revalidated at run time
 * that means that getStaticProps gets executet at runtime too for 404 page revalidation
 * but at run time we want to use the cache for performance and service decoupling (e.g. CMS)
 * @param context
 * @returns
 */
export const getStaticProps: GetStaticProps<TPageProps | {}> = (context) => {
  // server side generation runs with locale default and all other locales
  // we need to ensure that we dont try to render with a locale=default
  if (context.locale === 'default') {
    return { props: {} }
  }

  return getPageProps({
    type: PageTypeKey.SystemPage,
    key: SystemPageKey.NotFound,
    context,
    queryParams: {},
    pathParams: {},
    ip: null,
    host: null,
    requestCombiner: new RequestCombiner(),
    // do not use cache at build time
    disableCache: process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD,
    featureFlags: {},
    location: { lat: 0, lng: 0, city: '' },
    countryOverride: null,
  })
}

const NotFoundPage: NextPage<TPageProps> = (props) => {
  if (Object.keys(props).length === 0) {
    return <div />
  }
  return <BasePage {...props} />
}

export default NotFoundPage
